import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';

import { ButtonText } from '@powdr/constants';

export const RFPForm = ({
  reCaptchaPublicKey,
  processForm,
  isUserVerified,
  setUserVerified,
  handleCaptchaExpiry,
  buttonText,
}) => {
  const captchaRef = useRef();

  return (
    <form id="rfp" onSubmit={(e) => processForm(e, 'buk_X3MglVqa5fcEkxFAlVdEIEAE', null, captchaRef)}>
      <h3>Group Leader's Name *</h3>
      <input className="form-control" id="groupLeaderName" name="groupLeaderName" type="text" required="" />
      <h3>Mailing Address *</h3>
      <input className="form-control" id="mailingAddress" name="mailingAddress" type="text" required="" />
      <h3>City *</h3>
      <input className="form-control" id="city" name="city" type="text" required="" />
      <h3>State *</h3>
      <select className="form-control" id="state" name="state" required="">
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AB">Alberta</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="BC">British Columbia</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MB">Manitoba</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NB">New Brunswick</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NL">Newfoundland and Labrador</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="NU">Nunavut</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="ON">Ontario</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="RI">Rhode Island</option>
        <option value="SK">Saskatchewan</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option selected="selected" value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="YT">Yukon</option>
      </select>
      <h3>Zip / Postal Code *</h3>
      <input className="form-control" id="zipcode" name="zipCode" type="text" required="" />
      <h3>Country *</h3>
      <input className="form-control" id="country" name="country" type="text" required="" />
      <h3>Phone *</h3>
      <input className="form-control" id="phoneNumber" name="phoneNumber" type="tel" required="" />
      <h3>Email address *</h3>
      <input className="form-control" id="email" name="email" type="email" required="" />
      <h3>Type of Group *</h3>
      <select className="form-control" id="typeOfGroup" name="typeOfGroup" required="">
        <option value="School/Youth" selected="selected">School/Youth</option>
        <option value="Club/Adult">Club/Adult</option>
        <option value="College">College</option>
        <option value="Corporate">Corporate</option>
        <option value="Family">Family</option>
        <option value="Other">Other</option>
      </select>
      <h3>Group Name *</h3>
      <input className="form-control" id="groupName" name="groupName" type="text" required="" />
      <h3>Estimated Group Size</h3>
      <input className="form-control" id="estimatedGroupSize" name="estimatedGroupSize" type="number" required="" />
      <h3>Arrival Date *</h3>
      <input className="form-control" id="arrivalDate" name="arrivalDate" type="date" required="" />
      <h3>Lodging Needed (Y/N)? If yes, # of nights</h3>
      <input className="form-control" id="lodgingNeeded" name="lodgingNeeded" type="text" />
      <h3># of days of activity</h3>
      <input className="form-control" id="numberOfDays" name="numberOfDays" type="number" />
      <h3>Additional Comments</h3>
      <textarea id="additionalComments" name="additionalComments" rows="9" />

      <ReCAPTCHA
        ref={captchaRef}
        className="recaptcha"
        sitekey={reCaptchaPublicKey}
        theme="light"
        size="normal"
        onChange={() => setUserVerified(true)}
        onExpired={() => handleCaptchaExpiry()}
      />

      <button type="submit" value="Submit" disabled={!isUserVerified}>{buttonText || ButtonText.SUBMIT}</button>
    </form>

  );
};

RFPForm.propTypes = {
  reCaptchaPublicKey: PropTypes.string.isRequired,
  processForm: PropTypes.func.isRequired,
  setUserVerified: PropTypes.func.isRequired,
  isUserVerified: PropTypes.bool.isRequired,
  handleCaptchaExpiry: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

RFPForm.defaultProps = {
  buttonText: null,
};
